import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyCompetence } from "models/test-core/competences/company-competence";
import { CheckStatusCompanyCompetence } from "models/test-core/competences/check-status-company-competence";

@autoinject()
export class CoreCompanyCompetenceService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanyDiscStyleService");
  }

  public async list(parameters: UxDatatableParameters, questionaryId: number,languageId: number): Promise<UxDatatableResponse> {
    let a = this.client.baseUrl 
    return await this.client.post(`corecompanycompetence/list/${questionaryId}/${languageId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async update(model: CompanyCompetence): Promise<CompanyCompetence> {
    return await this.client.updateById("corecompanycompetence", model.id, null, model)
      .then((response) => { return <CompanyCompetence>response; });
  }

  public async changeState(model: CompanyCompetence): Promise<CompanyCompetence> {
    return await this.client.post(`corecompanycompetence/change-state/${model.id}`, model)
      .then((response) => { return <CompanyCompetence>response; });
  }

  public async changeAllState(questionaryId: number): Promise<CompanyCompetence> {
    return await this.client.post(`corecompanycompetence/change-all-state/${questionaryId}`)
      .then((response) => { return <CompanyCompetence>response; });
  }

  public async getHasAllActivateCompanyCompetences(questionaryId: number): Promise<CheckStatusCompanyCompetence> {
    return await this.client.get(`corecompanycompetence/get-has-all-activate-company-competences/${questionaryId}`)
      .then((response) => { return <CheckStatusCompanyCompetence>response; });
  }
}
