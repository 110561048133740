import { AgreementPlanService } from '../../../services/agreements/agreement-plan-service';
import { TestPlanItemListModal } from './test-plan-item-list-modal';
import { DialogCloseResult } from 'aurelia-dialog';
import { ContactModal } from '../../contacts/contact-modal';
import { DialogService } from 'aurelia-dialog';
import { Contact } from 'models/contacts/contact';
import { ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { Agreement } from 'models/agreements/agreement';
import { Company } from 'models/companies/company';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { ValidationControllerFactory } from 'aurelia-validation';
import { Notification } from 'common/services/notification';
import { CatalogService } from '../../../../services/catalogs/catalog-service';
import { CatalogBase } from 'models/catalogs/catalog-base';
import { ValidationController } from 'aurelia-validation';
import { Logger } from 'aurelia-logging';
import { autoinject, LogManager } from "aurelia-framework";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Router } from 'aurelia-router';
import { Plan } from 'models/plans/plan';
import { ContactService } from '../../../services/contacts/contact-service';
import { AgreementStatus } from 'models/agreements/agreementStatus';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { PlanType } from 'models/plans/planType';
import { CompanyDetailService } from 'main/admin/services/companies/company-detail-service';


@autoinject()
export class AgreementEdit {
  private logger: Logger;
  private companyId: number;
  private company: Company;
  private model: Agreement = new Agreement();


  private validationController: ValidationController;

  private plans: Plan[] = [];
  private allPlans: Plan[] = [];
  private selectionPlans: Plan[] = [];
  private contacts: Contact[] = [];
  private legalContact: Contact;
  private officialContact: Contact;
  private approveContact: Contact;

  private countries: CatalogBase[] = [];

  private disable: boolean = false;

  private params: any = {};

  private state: State;
 
  constructor(
    private store: StoreService,
    private catalogService: CatalogService,
    private contactService: ContactService,
    private agreementPlanService: AgreementPlanService,
    private agreementService: AgreementService,
    private dialogService: DialogService,
    private companyDetailService: CompanyDetailService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("AgreementEdit");

    this.state = this.store.state;
  }

  public async activate(params: any): Promise<void> {
    this.logger.info("activate");
    this.params = params;
  }

  public async bind(): Promise<any> {
    
    if (!isNaN(this.params.companyGroupId) && !isNaN(this.params.franchiseId)) {
      try {
        this.countries = await this.catalogService.getCountryAll();
        if (!isNaN(this.params.companyGroupId)) {
          this.companyId = +this.params.companyGroupId;
        }

        if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
          this.companyId = null;
        }
        else if (!isNaN(this.params.companyDetailId)) {
          this.companyId = +this.params.companyDetailId;
        }

        if (this.companyId) {

          this.agreementService.client.currentCompanyId = this.companyId;
          this.agreementPlanService.client.currentCompanyId = this.companyId;

          this.company = this.state.companies.find(x => x.id == this.companyId);
          this.contacts = await this.contactService.getall(this.companyId);                
          this.allPlans = await this.agreementPlanService.getBillablePlansByFranchiseId(this.params.franchiseId);     
          this.plans = this.allPlans.filter(x=> !x.isRobotHunter);     
          this.model = await this.agreementService.getLastAgreementByCompany(this.companyId);          
          this.selectionPlans = this.allPlans.filter(x => x.isRobotHunter && x.isTestItemsActive);

          if (!isNaN(this.params.agreementId)) {

            this.model = await this.agreementService.getById(+this.params.agreementId);

            if (this.model.agreementStatus !== AgreementStatus.InternalDraft) {
              this.disable = true;
            }

            this.legalContactChange();
            this.officialContactChange();
            this.approveContactChange();
            
          }
          else {           
            this.model.companyId = this.companyId;
            this.model.company = this.company;
          }     

          if (this.model.isDemoSelectionPlan) {
            this.selectionPlans = await this.companyDetailService.getDemoPlansByFranchiseId(this.params.franchiseId);
          }          
        

          this.configureValidationRules();
        }
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    // this.logger.debug("model", this.model);
    ValidationRules
      .ensure("description").displayName("main.admin.pages.agreements.description").required()
      //.ensure("countryId").displayName("catalog.country.title").required()
      //.ensure("planId").displayName("main.admin.pages.agreements.plan").required()
      .ensure("numApprovedUsers").displayName("main.admin.pages.agreements.numApprovedUsers").required()
      .ensure("talents").displayName("main.admin.pages.agreements.talents").required().matches(/^[0-9]*$/)
      .ensure("selectionTalents").displayName("main.admin.pages.agreements.talents").required().matches(/^[0-9]*$/)
      .ensure("corporateID").displayName("main.admin.pages.agreements.corporateid").required()
      .ensure("phone").displayName("main.admin.pages.agreements.phone").required()
      .ensure("address").displayName("main.admin.pages.agreements.address").required()
      .ensure("numberOfEmployees").displayName("main.admin.pages.agreements.numberOfEmployees").required().matches(/^[0-9]*$/)
      .ensure("agreementDuration").displayName("main.admin.pages.agreements.duration").required()
      .ensure("startDate").displayName("main.admin.pages.agreements.startDate").required()
      .ensure("endDate").displayName("main.admin.pages.agreements.endDate").required()
      .ensure("whoApproveId").displayName("main.admin.pages.agreements.whoApprove").required()
      .ensure("officialInterlocutorId").displayName("main.admin.pages.agreements.officialInterlocutor").required()
      .ensure("demoSelectionStartDate").displayName("main.admin.pages.companies.demoSelectionStartDate").required().when(x => this.model.isDemoSelectionPlan)
      .ensure("selectionPlanId").displayName("main.admin.pages.companies.selectionPlanId").required().when(x => this.model.isDemoSelectionPlan)
      .on(this.model);
  }

  public async submit(keepEditing: boolean): Promise<void> {

    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }
  private async onIsDemoChange(isDemo: boolean) {
    if (isDemo) {
      this.model.demoSelectionStartDate = new Date();
      this.selectionPlans = (await this.companyDetailService.getDemoPlansByFranchiseId(this.params.franchiseId))
      .filter(x=> x.isRobotHunter && x.isTestItemsActive);
    }
    else{
      this.selectionPlans = this.allPlans.filter(x=> x.isRobotHunter)
                                          .filter(x=> x.isTestItemsActive);
    }      
  }
  get canSave() {
    return !this.agreementService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    try {
      if (!this.model.id) {

        this.model = await this.agreementService.create(this.model)

        if (!keepEditing) {
          this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.model.id });
        }
        else {
          this.router.parent.navigateToRoute("edition-child-router", { agreementId: this.model.id });
        }

        this.notification.success("notifications.item_added");

      } else {

        await this.agreementService.update(this.model);

        this.notification.success("notifications.item_edited");
        
        if (!keepEditing) {
          this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.model.id });
        }

      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async createContact(): Promise<void> {
    this.logger.info("createContact");
    let item: Contact = new Contact();
    item.companyId = this.company.id;

    return await this.dialogService.open({
      viewModel: ContactModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        try {
          item = await this.contactService.create(item);

          this.contacts.unshift(item);
          this.notification.success("main.admin.pages.contacts.notifications.added");
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });
  }

  private legalContactChange() {
    this.legalContact = this.contacts.find(x => x.id === this.model.legalRepresentativeId);
    this.model.legalRepresentative = this.legalContact.fullName;
  }

  private officialContactChange() {
    this.officialContact = this.contacts.find(x => x.id === this.model.officialInterlocutorId);
    this.model.officialInterlocutor = this.officialContact.fullName;
  }

  private approveContactChange() {
    this.approveContact = this.contacts.find(x => x.id === this.model.whoApproveId);
    this.model.WhoApprove = this.approveContact.fullName;
  }

  private async viewTestPlanItems(): Promise<void> {
    this.logger.info("PlanDetailsPopup");    
    return await this.dialogService.open({
      viewModel: TestPlanItemListModal,
      model: this.model.planId
    }).whenClosed(async (response: DialogCloseResult) => null);
  }
  private async viewSelectionPlanItems(): Promise<void> {
    this.logger.info("PlanDetailsPopup");    
    return await this.dialogService.open({
      viewModel: TestPlanItemListModal,
      model: this.model.selectionPlanId
    }).whenClosed(async (response: DialogCloseResult) => null);
  }

  private async cancel() {
    if (this.model.id) {
      return await this.router.navigateToRoute("readonly-child-router", { agreementId: this.model.id });
    } else {
      return await this.router.navigateToRoute("agreements");
    }
  }

}
