
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PlanService } from "../../services/plans/plan-service";
import { Plan } from "models/plans/plan";
import { CoreCompanyService } from "../../services/companies/core-company-service";
import { Company } from "models/companies/company";
import { SpendMode } from "models/plans/spendMode";
import { PlanType } from "models/plans/planType";

@autoinject()
export class PlanEdit {

  private plans: Plan[];
  private logger: Logger;

  private model: Plan = new Plan();
  private franchise: Company;
  private validationController: ValidationController;
  private isRobotHunter: boolean = false;
  constructor(
    private coreCompanyService: CoreCompanyService,
    private planService: PlanService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PlanEdit");
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.plans.plan_name").required()
      .ensure("planType").displayName("main.admin.pages.plans.plan_type").required()
      .ensure("talents").displayName("main.admin.pages.plans.talents").required().matches(/^[0-9]*$/)
      .ensure("talentChargeFrequency").displayName("main.admin.pages.plans.talent_charge_frequency").required()
      .ensure("demoLimitTime").displayName("main.admin.pages.plans.demo_limit_time").required()
      .ensure("consultSmartPerProcess").displayName("main.admin.pages.plans.talents").required().matches(/^[0-9]*$/)
  }

  private async activate(params: any): Promise<void> {
    // this.logger.info("activate");
    if (!isNaN(params.franchiseId)) {
      try {        
        if (params.isRobotHunter) {
          this.isRobotHunter = JSON.parse(params.isRobotHunter);
          this.model.additionalTalentsSpendMode  = SpendMode.UniqueRetail;
        }       
        this.plans = await this.planService.getAll(this.isRobotHunter);
        this.franchise = await this.coreCompanyService.getById(+params.franchiseId);
        this.model.isRobotHunter = this.isRobotHunter;
        if (!isNaN(params.planId)) {
          this.model = await this.planService.getById(+params.planId);
        }
        else {
          this.model.companyId = this.franchise.id;
        }
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }
  onPlanTypeChange() {
    if (this.isRobotHunter && this.model.planType === PlanType.Demo){
      this.model.hasEmploymentPortal = true;
      this.model.isDataBasePublic = true;
    }
    if (this.isRobotHunter && this.model.planType === PlanType.Billable){
      this.model.hasEmploymentPortal = false;
      this.model.isDataBasePublic = false;
    }
  }
  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.planService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    if (this.model.additionalTalentsSpendMode == SpendMode.Unlimitted){
     return  this.notification.error("main.admin.pages.plans.messages.plan_edited_unilimitted");
    }
    if (!this.model.id) {
      return await this.planService.create(this.model)
        .then(async (plan: Plan) => {
          this.model.id = plan.id;
          this.notification.success("main.admin.pages.plans.messages.plan_edited");
          if (!keepEditing) {
            return await this.router.navigateToRoute("plans");
          } else {
            return await this.router.navigateToRoute("plan-child-router", { planId: plan.id });
          }

        }).catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.planService.update(this.model)
        .then(async () => {
          this.notification.success("main.admin.pages.plans.messages.plan_edited");

          if (!keepEditing) {
            return await this.router.navigateToRoute("plans");
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  // public cancel(): void {
  //   this.logger.info("cancel");
  //   this.router.parent.navigate(`plans/franchise/${this.model.companyId}/plans`);
  // }
}
