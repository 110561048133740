import { Company } from './company';

export class CompanyDetail {
  id: number;
  company: Company = new Company();
  companyId: number;
  economicSectorId: number;
  countryId: number;
  regionId: number;
  cityId: number;
  logoUrl: string;
  logo: string;
  logoFileName: string;
  userNumber: number;
  isDemo: boolean;
  planId: number;

  isSelectionDemo: boolean;
  selectionPlanId: number;
}
