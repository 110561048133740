import { I18N } from 'aurelia-i18n';
import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { CoreCompanyCompetenceService } from '../../../services/test-core/core-company-competence-service';
import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { ChangeStateConfirmation } from 'main/components/change-state-confirmation';
import { DichotomousCoupleListModal } from './dichotomous-couple-list-modal';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { QuestionaryService } from 'main/admin/services/questionaries/questionary-service';
import { Questionary } from 'models/questionaries/questionary';
import { Tests } from 'models/test-person/tests';
import { CheckStatusCompanyCompetence } from 'models/test-core/competences/check-status-company-competence';
import { Language } from 'models/localizations/language';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';
import { Languages } from 'models/localizations/languages';



@autoinject()
export class CompanyCompetenceList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;

  private isLoaded: boolean = false;
  private competenceQuestionary: Questionary;
  private questionaryId: number;
  private isAllActivate: boolean = false;
  private isCheckActivate: boolean = false;
  private hasDimension: boolean = true;
  private hasSelectLanguage: boolean = false;
  public languageList: Language[] = [];
  public languageId: number;
  constructor(
    private i18n: I18N,
    private dialogService: DialogService,
    private store: StoreService,
    private companyCompetenceService: CoreCompanyCompetenceService,
    private notification: Notification,
    private languageService: CoreLanguageService,
    private coreCompanyService: CoreCompanyService,
    private questionaryService: QuestionaryService) {

    this.logger = LogManager.getLogger("CompanyCompetenceList");

    this.state = this.store.state;

  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.companyCompetenceService.list(this.parameters, this.questionaryId, this.languageId);
  }

  private async activate(params: any): Promise<void> {
    this.logger.debug("activate");
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }


  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {     
      this.companyId = +this.params.companyId;
      if (this.companyId) {

        this.companyCompetenceService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        let settings = await this.coreCompanyService.getSettingsById(this.companyId);
        this.questionaryId = settings.competencesQuestionaryId;

        if (this.questionaryId == 0)
          this.questionaryId = Tests['Competences'];

        if (this.questionaryId != Tests['Competences'])
        {
          this.hasDimension = false;
          this.hasSelectLanguage = true;   
        }else{  
          this.hasSelectLanguage = false;
          this.languageId = Languages.Spanish;
        }    


        this.competenceQuestionary = await this.questionaryService.getById(this.questionaryId);
       
        this.isLoaded = true;

        this.languageList = await this.languageService.getAll();
        this.setUserLanguage();

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
        this.checkAllActivate();
      })
      .catch(error => {
        this.notification.error(error);
      });

  }
  private async checkAllActivate(): Promise<void> {
    let status = await this.companyCompetenceService.getHasAllActivateCompanyCompetences(this.questionaryId);

    switch (status) {
      case CheckStatusCompanyCompetence.Active:
        this.isAllActivate = true;
        this.isCheckActivate = true;
        break;
      case CheckStatusCompanyCompetence.Inactive:
        this.isAllActivate = false;
        this.isCheckActivate = true;
        break;
      case CheckStatusCompanyCompetence.Pending:
        this.isAllActivate = false;
        this.isCheckActivate = false;
        break;
    }


  }
  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async save(item: CompanyCompetence): Promise<void> {
    item.languageId = this.languageId;
    return await this.companyCompetenceService.update(item)
      .then((model: CompanyCompetence) => {

        this.logger.debug("model.id", model.id);
        item.id = model.id;

        this.notification.success("main.admin.pages.test_core.company_competences.notifications.edited");
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  public async changeAllState(): Promise<void> {
    try {
      await this.companyCompetenceService.changeAllState(this.questionaryId);
      this.notification.success("main.admin.pages.test_core.company_competences.notifications.all_competences_edited");
      return await this.databind();
    } catch (error) {
      this.notification.error(error);
    }
  }

  public async changeState(item: CompanyCompetence): Promise<void> {
    this.logger.debug("item.isActive", item.isActive);
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: this.i18n.tr(item.competenceName)
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.companyCompetenceService.changeState(item)
          .then((model: CompanyCompetence) => {

            this.logger.debug("model.id", model.id);
            item.id = model.id;

            this.notification.success("main.admin.pages.test_core.company_competences.notifications.edited");
            return this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });

      }
      else {
        item.isActive = !item.isActive;
      }

    });

  }

  public async view(item: CompanyCompetence): Promise<void> {
    this.logger.debug("viewDichotomousCouples");
    return await this.dialogService.open({
      viewModel: DichotomousCoupleListModal,
      model: item
    }).whenClosed(() => null);
  }
  public setUserLanguage() {    
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {      
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languageList.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.languageId = activeLang.id;             
      }
    }
  }
  public async changeLanguage (){      
    await this.databind();
    }
}
