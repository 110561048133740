import { PlanService } from '../../services/plans/plan-service';
import { CoreCompanyService } from '../../services/companies/core-company-service';
import { CompanyGroup } from 'models/companies/company-group';
import { CatalogBase } from 'models/catalogs/catalog-base';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { CatalogService } from "../../../services/catalogs/catalog-service";
import { CatalogChild } from "models/catalogs/catalog-child";
import { CompanyDetailService } from '../../services/companies/company-detail-service';
import { CompanyDetail } from 'models/companies/company-detail';
import { FileAcceptValidator } from 'common/utils/file-accept-validator';
import { Plan } from 'models/plans/plan';
import { CompanyGroupService } from '../../services/companies/company-group-service';
import { CompanyEditedMessage } from 'messages/companyEditedMessage';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Company } from 'models/companies/company';
import { PlanType } from 'models/plans/planType';

@autoinject()
export class CompanyEdit {

  private logger: Logger;

  private franchise: Company;
  private franchiseId: number;
  
  private companyGroup: CompanyGroup;

  private model: CompanyDetail = new CompanyDetail();
  private validationController: ValidationController;

  private demoPlans: Plan[] = [];  
  private demoAllPlans: Plan[] = [];
  private demoSelectionPlans: Plan[] = [];
  private economicSectors: CatalogBase[] = [];
  private countries: CatalogBase[] = [];
  private regions: CatalogChild[] = [];
  private cities: CatalogChild[] = [];

  constructor(
    private planService: PlanService,
    private catalogService: CatalogService,
    private coreCompanyService: CoreCompanyService,
    private companyGroupService: CompanyGroupService,
    private companyService: CompanyDetailService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    private ea: EventAggregator) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CompanyEdit");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.companies.name").required()
      .on(this.model.company)
      .ensure("economicSectorId").displayName("catalogs.economic_sectors.title").required()
      .ensure("countryId").displayName("catalogs.countries.title").required()
      .ensure("regionId").displayName("catalogs.regions.title").required()
      .ensure("cityId").displayName("catalogs.cities.title").required()

      .ensure("userNumber").displayName("main.admin.pages.companies.user_number").matches(/^[0-9]*$/).required()
      .ensure("isDemo").displayName("main.admin.pages.companies.demo").required()

      .ensure("planId").displayName("main.admin.pages.companies.plan").required().when(x => this.model.isDemo)

      .on(this.model);
  }

  private async activate(params: any): Promise<any> {
    this.logger.info("activate");

    try {

      if (!isNaN(params.franchiseId) && !isNaN(params.companyGroupId)) {
        this.franchiseId = +params.franchiseId;
        this.companyGroup = await this.companyGroupService.getById(+params.companyGroupId);
        this.economicSectors = await this.catalogService.getEconomicSectorAll();
        this.countries = await this.catalogService.getCountryAll();
        this.demoAllPlans = (await this.companyService.getDemoPlansByFranchiseId(this.franchiseId));
        this.demoPlans = this.demoAllPlans.filter(x=> !x.isRobotHunter);
        this.demoSelectionPlans = this.demoAllPlans
        .filter(x=> x.isRobotHunter && x.isTestItemsActive);
        this.franchise = await this.coreCompanyService.getById(+params.franchiseId);

        if (!isNaN(params.companyDetailId)) {
          this.model = await this.companyService.getById(+params.companyDetailId);
          this.regions = await this.catalogService.getRegionsByCountryId(this.model.countryId);
          this.cities = await this.catalogService.getCitiesByRegionId(this.model.regionId);
        }
        else {
          this.model.company.parentId = this.companyGroup.companyId;
          this.model.company.createdOn = new Date();         
        }
        this.configureValidationRules();

      }
    } catch (error) {
      this.notification.error(error);
    }
  }
  filterDemo(isDemo: boolean) {  
    if (!isDemo) {
      this.model.planId = null;
      this.model.selectionPlanId = null;
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.companyService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    if (!this.model.id) {
      return await this.companyService.create(this.model)
        .then(async (response: CompanyDetail) => {
          this.model.companyId = response.companyId;

          // trigger company edited event
          this.ea.publish(new CompanyEditedMessage(response.company));

          this.notification.success("main.admin.pages.companies.messages.company_added");

          if (!keepEditing) {
            return await this.router.navigateToRoute("company-groups");
          } else {
            return await this.router.navigateToRoute("company-child-router", { companyGroupId: this.model.company.parentId, companyDetailId: this.model.companyId });
          }

        }).catch(error => {
          this.notification.error(error);
        });
    } else {

      return await this.companyService.update(this.model)
        .then(async (response: CompanyDetail) => {
          this.model.logoUrl = this.model.logoUrl;

          // trigger company edited event
          // this.logger.debug("updated company", response.company);

          this.ea.publish(new CompanyEditedMessage(response.company));

          this.notification.success("main.admin.pages.companies.messages.company_edited");

          if (!keepEditing) {
            return await this.router.navigateToRoute("company-groups");
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  public async filterRegion(countryId: number): Promise<void> {
    this.model.regionId = null;
    this.model.cityId = null;

    this.regions = await this.catalogService.getRegionsByCountryId(countryId);
  }

  public async filterCity(regionId: number): Promise<void> {
    this.model.cityId = null;

    this.cities = await this.catalogService.getCitiesByRegionId(regionId);
  }

  public setFile($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];
    let fileValidator = FileAcceptValidator.parse("image/*", 1024 * 300);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var base64data: any = reader.result;
        this.model.logo = base64data;
        this.model.logoFileName = file.name;
        this.model.logoUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.companies.errors.error_bad_image");
    }
  }

  public unSetFile(): void {
    this.model.logo = null;
    this.model.logoFileName = null;
    this.model.logoUrl = null;
  }
}
